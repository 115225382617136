import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
Vue.config.productionTip = false;

Array.prototype.sortBy = function(p) {
  return this.slice(0).sort(function(a,b) {
    return (a[p] > b[p]) ? 1 : (a[p] < b[p]) ? -1 : 0;
  });
}

import PrismicVue from '@prismicio/vue';
import linkResolver from './link-resolver'; // Update this path if necessary

const accessToken =
  'MC5ZS0J4MGhJQUFDUUF3ZF9J.77-9a--_ve-_vX0-77-977-9AATvv712ae-_vW7vv73vv73vv73vv71LMRImBCpf77-977-977-9Vzjvv70'; // Leave empty if your repo is public
const endpoint = 'https://cryptoatm.cdn.prismic.io/api/v2'; // Use your repo name

// Register plugin
Vue.use(PrismicVue, {
  endpoint,
  apiOptions: { accessToken },
  linkResolver,
});

import VuePageTitle from 'vue-page-title';
Vue.use(VuePageTitle, {
  // prefix: 'My App - ',
  // suffix: '- My App '
});

import router from './router';
import i18n from './i18n';

window.vm = new Vue({
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
