<template>
  <v-app>
    <v-app-bar app color="secondary" elevate-on-scroll>
      <router-link
        tag="a"
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <img src="./assets/logo.svg" width="40" class="mr-2 d-inline-block" />
        <span class="primary--text text-md-h6 font-weight-medium">
          {{ $t("help") }}
        </span>
      </router-link>

      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn text @click="changeLanguage" v-on="on">
              <img src="./assets/languages-icon.svg" width="40" />
            </v-btn>
          </template>
          <span>{{ $t("change_language") }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn text to="/locations">
              <img src="./assets/location-icon.svg" width="32" v-on="on" />
            </v-btn>
          </template>
          <span>{{ $t("locations") }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              text
              href="https://api.whatsapp.com/send?phone=34604812990&text="
              target="_blank"
            >
              <img src="./assets/whatsapp-icon.svg" width="32" v-on="on" />
            </v-btn>
          </template>
          <span>{{ $t("send_message") }}</span>
        </v-tooltip>
      </v-toolbar-items>
    </v-app-bar>
    <router-view :categories="categories"></router-view>
    <v-footer>
      <v-container>
        <v-row>
          <v-col cols="12" md="8" class="pa-5">
            <h4 class="text-h6 primary--text mb-5">
              {{ $t("browse_by_topic") }}
            </h4>
            <v-btn
              v-for="item in categories"
              :key="item.slug"
              text
              class="mr-3 mb-3 secondary primary--text"
              :to="'/categories/' + item.slug"
            >
              {{ item.name }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    categories: [],
  }),
  watch: {
    "$i18n.locale"() {
      this.getCategories();
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale === "es" ? "es-es" : "en-gb";
    },
  },
  methods: {
    changeLanguage() {
      if (this.$i18n.locale === "es") {
        this.$i18n.locale = "en";
      } else {
        this.$i18n.locale = "es";
      }
    },
    async getCategories() {
      const document = await this.$prismic.client.getSingle("categories", {
        lang: this.locale,
      });
      this.categories = document.data.categories;
    },
  },
  created() {
    this.getCategories();
  },
};
</script>

<style>
body {
  font-family: "Montserrat";
}
li > * {
  vertical-align: text-top;
}
.v-application a {
  color: #1565c0 !important;
}
</style>